import { defineStore } from "pinia";
import axios from "axios";

export const UserStore = defineStore("userStore", {
  state: () => ({
    entrypoint: null,
    selectedLanguage: "es",
    token: "",
    flMark: "",
    userAccount: {
      token: null,
    },
  }),
  actions: {
    clearEntrypoint() {
      this.entrypoint = null;
    },
    setFlMark(flMark: string) {
      this.flMark = flMark;
    },
    setEntrypoint(entrypoint: string) {
      // @ts-ignore
      this.entrypoint = entrypoint === "" ? null : entrypoint;
    },
    logout() {
      // this.selectedLanguage = "en";
      this.token = "";
      this.userAccount = {
        token: null,
      };
    },
    setLanguage(language: string | string[]) {
      axios.defaults.params = {};
      axios.defaults.params["lang"] = language;
      this.selectedLanguage = language.toString();
    },

    setAccountStatus(status: string) {
      // @ts-ignore
      this.userAccount.payment_status = status;
    },

    setToken(token: string) {
      this.token = token;
    },
    setAccount(profile: object) {
      // @ts-ignore
      this.userAccount = profile;
    },
  },
  getters: {
    getFlMark(): string {
      return this.flMark;
    },
    getEntrypoint(): string {
      // @ts-ignore
      return this.entrypoint;
    },
    getLanguage(): string {
      return this.selectedLanguage;
    },
    trialEntitlement(): boolean {
      // @ts-ignore
      return this.userAccount.active_subscription === "0";
    },
    getToken(): string {
      return this.token;
    },
    getAccount(): object {
      return this.userAccount;
    },
    isLoggedIn(): boolean {
      return this.token !== "";
    },
    isEntitled(): boolean {
      // @ts-ignore
      return this.token !== "";
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "flxUser",
        storage: localStorage,
      },
    ],
  },
});
